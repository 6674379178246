import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  amount: number;
  small?: boolean;
  endSubscription?: string;
  onlyText?: boolean;
}

const getDiffMonth = (amount: number, endSubscription: string) => {
  if (!endSubscription) return <span>{`${amount / 100}€ / mois`}</span>;

  const endDate = new Date(
    parseInt(endSubscription.split('/')[2]),
    parseInt(endSubscription.split('/')[1]) - 1,
    parseInt(endSubscription.split('/')[0])
  );
  const today = new Date();

  // Si la date de fin est passée, retourner le montant tel quel
  if (endDate < today) {
    return <span>{`${amount / 100}€ / mois`}</span>;
  }

  // Calculer la différence en mois en tenant compte des 15 jours
  const monthDiff =
    (endDate.getFullYear() - today.getFullYear()) * 12 +
    (endDate.getMonth() - today.getMonth());

  // Ajuster le nombre de mois en fonction du jour du mois
  const dayDiff = endDate.getDate() - today.getDate();
  const monthAdjustment = dayDiff <= -15 ? -1 : dayDiff >= 15 ? 1 : 0;

  const diffInMonths = Math.max(1, monthDiff + monthAdjustment);

  return <span>{`${Math.round(amount / diffInMonths) / 100}€ / mois`}</span>;
};

const PriceByMonth: React.FC<Props> = ({
  amount,
  small,
  endSubscription,
  onlyText,
}) => {
  const Price = getDiffMonth(amount, endSubscription || '');

  return onlyText ? (
    Price
  ) : small ? (
    <BoldText>{Price}</BoldText>
  ) : (
    <h2>{Price}</h2>
  );
};

const BoldText = styled.h4`
  font-weight: 700;
`;

export default PriceByMonth;
